// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import './firebase';
import { routes } from './routes';

// Get basename from environment variable (e.g., for deployment in a subdirectory)
const basename = '/';

const router = createBrowserRouter(routes, {
  basename,
});

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
