import React, { Fragment } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';

export const Navigation = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  // Check if navigation should be shown based on location state
  const showNavigation = location.state?.from === 'gameCard';

  return (
    <Fragment>
      {showNavigation && (
        <svg
          onClick={() => navigate(-1)}
          width="24px"
          height="24px"
          viewBox="0 0 1024 1024"
          xmlns="http://www.w3.org/2000/svg"
          className="text-darkGrey m-2 cursor-pointer"
        >
          <path
            fill="currentColor"
            d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"
          />
          <path
            fill="currentColor"
            d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"
          />
        </svg>
      )}
      {children || <Outlet />}
    </Fragment>
  );
};
