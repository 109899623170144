import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBB3qFDPwZNYXZ07vCB7ux4Y6Z3kt_cKTg',
  authDomain: 'hr-nogomet.firebaseapp.com',
  projectId: 'hr-nogomet',
  storageBucket: 'hr-nogomet.firebasestorage.app',
  messagingSenderId: '551412164754',
  appId: '1:551412164754:web:e288ffe86bfafd84ee1e4b',
  measurementId: 'G-V0CB4VNF32',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { analytics };
