// src/routes.js
import React, { lazy, Suspense } from 'react';
import { Navigation } from './shared/Navigation';
import ErrorPage from './pages/ErrorPage';

// Lazy-load components
const LandingStandings = lazy(() => import('./pages/LandingStandings'));
const LandingShotmaps = lazy(() => import('./pages/LandingShotmaps'));
const Results = lazy(() => import('./pages/Results'));
const PrematchDetails = lazy(() => import('./pages/PrematchDetails'));
const Standings = lazy(() => import('./pages/Standings'));
const MatchDetails = lazy(() => import('./pages/MatchDetails'));
const TeamPage = lazy(() => import('./pages/TeamPage'));
const Competition = lazy(() => import('./pages/Competition'));
const Player = lazy(() => import('./pages/Player'));
const CompetitionWeb = lazy(() => import('./web/CompetitionWeb'));
const PrematchWeb = lazy(() => import('./web/PrematchWeb'));
const TeamWeb = lazy(() => import('./web/TeamWeb'));
const ShotMap = lazy(() => import('./components/player/ShotMap'));

// Layout component to wrap routes with Navigation
const NavigationLayout = ({ children }) => <Navigation>{children}</Navigation>;

// Define routes
export const routes = [
  {
    path: '/',
    element: (
      <Suspense fallback={<div>Učitavanje...</div>}>
        <LandingStandings />
      </Suspense>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/landing/shotmap',
    element: (
      <Suspense fallback={<div>Učitavanje...</div>}>
        <LandingShotmaps />
      </Suspense>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/shotmap-widget',
    element: (
      <Suspense fallback={<div>Učitavanje...</div>}>
        <ShotMap />
      </Suspense>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/prematch/:matchId',
    element: (
      <Suspense fallback={<div>Učitavanje...</div>}>
        <PrematchDetails />
      </Suspense>
    ),
    errorElement: <ErrorPage />,
  },
  // Routes with Navigation layout
  {
    element: <NavigationLayout />,
    errorElement: <ErrorPage />, // Global errorElement for all routes under Navigation
    children: [
      {
        path: '/standings',
        element: (
          <Suspense fallback={<div>Učitavanje...</div>}>
            <Standings />
          </Suspense>
        ),
      },
      {
        path: '/match/:matchId',
        element: (
          <Suspense fallback={<div>Učitavanje...</div>}>
            <MatchDetails />
          </Suspense>
        ),
      },
      {
        path: '/results',
        element: (
          <Suspense fallback={<div>Učitavanje...</div>}>
            <Results />
          </Suspense>
        ),
      },
      {
        path: '/team/:teamId',
        element: (
          <Suspense fallback={<div>Učitavanje...</div>}>
            <TeamPage />
          </Suspense>
        ),
      },
      {
        path: '/competition/:shortname',
        element: (
          <Suspense fallback={<div>Učitavanje...</div>}>
            <Competition />
          </Suspense>
        ),
      },
      {
        path: '/player/:playerId',
        element: (
          <Suspense fallback={<div>Učitavanje...</div>}>
            <Player />
          </Suspense>
        ),
      },
      // Nested /web routes
      {
        path: '/web',
        children: [
          {
            path: 'competition/:shortname',
            element: (
              <Suspense fallback={<div>Učitavanje...</div>}>
                <CompetitionWeb />
              </Suspense>
            ),
          },
          {
            path: 'team/:teamId',
            element: (
              <Suspense fallback={<div>Učitavanje...</div>}>
                <TeamWeb />
              </Suspense>
            ),
          },
          {
            path: 'prematch/:matchId',
            element: (
              <Suspense fallback={<div>Učitavanje...</div>}>
                <PrematchWeb />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
];
